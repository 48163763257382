html {
  background-color: rgb(82, 164, 247);
}
a { 
  text-decoration: none;
  color: rgb(47, 44, 222);
}
.App {
  max-width: 1000px;
  text-align: center;
  margin: 0 auto;
  padding: 15px;

}
.Header {
  display: grid;
  grid-template-columns: 9fr 1fr;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

 /* For screens under 600px wide */
@media (max-width:600px) { 
  .App {
    
  }
  .Header {
    grid-template-columns: 1fr;
  }
}