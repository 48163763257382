.MatchDetails {
    background-color: rgba(197, 197, 197, 0.839);
    margin-bottom:  20px;
    padding: 10px;
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 2fr 5fr 1fr;
    gap: 5px;
    border-radius: 10px;
}
.SpoiledResults {
    background-color: rgba(122, 200, 122, 0.638);
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: repeat(5, 1fr);
    grid-auto-flow: column;
    text-align: center;
    align-items: center;
}