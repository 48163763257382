 .auth-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.form-container {
  background-color: rgba(122, 200, 122, 0.638);
  padding: 25px 10px;
}
.form-container form {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px
}
.form-container form label{
  grid-column: 1 / span 2;
  text-align: right;
}
.form-container form input {
  grid-column: 3 / span 3;
}
.form-container form .auth-button {
    grid-column: 3 / span 2;
}